.static-table{
    position: fixed;
    top: 40;
    left: 0;
    height: "100%";
  background-color: rgb(234, 231, 235);
    zIndex: 1000;
}

#scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#scroll::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#scroll::-webkit-scrollbar-thumb
{
	background-color: #325c67;
}