.main-container1 {
  display: flex;
  flex-direction: row;
  background-color: #eae7eb;
  height: 100vh;
}

.main-cards1 {
  margin: auto; /* Center the cards horizontally */
  text-align: center;
  padding-left: 350px;
}

.row-cards1 {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.card1 {
  width: 300px;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: rgb(0, 79, 120);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-inner1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card1 h1 {
  margin-top: 20px;
}

.card1 img {
  width: 200px;
  height: 140px;
  margin-top: 50px;
}

#logo1 {
  width: 200px;
  height: 100px !important;
  margin-top: 70px !important;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .row-cards1 {
    flex-direction: column;
    align-items: center;
  }

  .card-2 {
    margin-top: 570px;
  }

  .card1 {
    margin-bottom: 20px;
  }
}

@media (min-width: 336px) and (max-width: 520px) {
  .main-cards1 {
    margin: auto;
    text-align: center;
    padding-left: 0px;
  }

  .main-container1 {
    display: flex;
    flex-direction: row;
    background-color: #eae7eb;
    height: 760px;
  }
}

@media (min-width: 520px) and (max-width: 865px) {
  .main-cards1 {
    margin: auto;
    text-align: center;
    padding-left: 120px;
  }
}

@media (min-width: 865px) and (max-width: 913px) {
  .main-cards1 {
    margin: auto;
    text-align: center;
    padding-left: 153px;
  }
}

#banner_name {
  position: absolute;
  top: 125px;
  left: 420px;
}

@media (max-width: 768px) {
  #banner_name {
    position: absolute;
    top: 125px;
    left: 0px;
  }
}
