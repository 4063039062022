#form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  height: 100%;
  margin-top: 10px;
  text-align: center;
  border-radius: 20px;
  padding-top: 0px;

}

@media (min-width: 300px) and (max-width: 560px){
  #form {
    padding: 1px !important;
  }
}

@media (min-width:"560px"){
  #form { 
    padding: 50px !important;
  }

}

.grid2 {
  margin-top: 20px;
}

.form {
  max-width: auto;
  padding: 0;
}

.form input {
  width: 200px;
}

.date {
  width: 800px;
}


.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
  font-size: 20px !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: #306ef3 !important;
}

.css-1fu7jd5-MuiButtonBase-root-MuiButton-root{
  width: 20% !important;
  background-color: #306ef3 !important;
}

.display {
  display: none;
}

@media screen and (max-width: 1000px) {
  .display {
    display: block;
  }
  .dispaly {
    display: none;
  }
  .dashboard_graph {
    width: auto;
  }

}

@media screen and (max-width: 820px) {
  .display {
    display: block;
  }
  .dispaly {
    display: none;
  }
  .dashboard_graph {
    width: 220px;
  }
}

@media screen and (min-width: 1000px) {

 .change{
   margin-left: 10px;
 }

}

.gauge-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 23px;
  /* margin-right:55px; */
}

.gauge {
  display: inline-block;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: white;
  border:20px solid #004f78;
  position: relative;
}

.gauge::before {
  content: attr(data-value) '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
  color: black;
}
.dropdown-container {
 /* margin-top: 20px;  */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    background: white;
    padding: 20px;
    border-radius: 9px;
    height: 300px;
}

.dropdown {
  width: 200px;
  padding: 10px;
  border: 2px solid #524e4ef2;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 16px;
  color: #524e4ef2;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown:focus {
  outline: none;
  border-color: #333333;
}


