.css-1udmlmp-MuiTypography-root{
    font-size: 30px !important;
    position: absolute !important;
}

.logo1{
    height: 57px;
    padding-left: 65px !important;
}

.logoHead{
    height: 57px;
    padding-left: 65px !important;
}

.logo11{
    height: 50px;
    padding-left: 60px !important;
}

.css-1gaadpk-MuiPaper-root-MuiAppBar-root{
    box-shadow: none !important;
}

/* .icon_button {
    height: 30px !important;
} */

@media (max-width: 450px) {
    .css-13cahrp-MuiTypography-root{
        left:7px !important;
        font-size: 17px !important;
    }

    .css-1o0qac4{
        left:7px !important;
    }
}


@media (min-width: 450px) {
    .css-13cahrp-MuiTypography-root{
        left:112px !important;
    }
    .css-1o0qac4{
        left:112px !important;
    }
}




