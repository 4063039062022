.logo1 {
  height: 64px;
  padding-left: 20px !important;
}



.css-17tud1b {
  background-color: #a2abe3 !important;
}
