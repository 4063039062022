/* .css-i4bv87-MuiSvgIcon-root{
    height: 15px ;
} */

@media screen and (min-width: 480px) {
  .css-8mcjw0-MuiStack-root > :not(style):not(style) {
    margin: 35px !important;
  }
}

@media screen and (max-width: 990px) {
  .css-8mcjw0-MuiStack-root > :not(style):not(style) {
    margin: 35px !important;
  }
}

.mainn {
  margin-left: 250px;
  width: 100%;
}

.mainn1 {
  margin-left: 250px;
  width: 140%;
}

@media (min-width: 1050px) {
  .css-v2n0fd-MuiContainer-root {
    max-width: 100% !important;
  }
}
/* 
.css-8mcjw0-MuiStack-root {
  margin-left: 100px;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  margin-left: 245px;
} */
/* 
#searchTab {
  margin-left: 120px;
}

#arrowButton {
  margin-left: 50px;
} */
