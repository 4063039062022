.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .popup form {
    display: flex;
    flex-direction: column;
  }
  
  .popup input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .popup button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: #0056b3;
  }
  
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 9999;
  }
  
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width:600px;
    transform: translateY(-50px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-content {
    margin-top: 20px;
  }
  
  .open-modal-btn {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    
  }
  
  .open-modal-btn:focus ~ .modal-overlay{
      opacity: 1;
      visibility: visible;
  }
  .open-modal-btn:focus ~ .modal-overlay .modal{
      opacity: 1;
      transform: translateY(0);
  }
  .close-modal-btn {
      background-color: transparent;
      border: none;
      color: #333;
      font-size: 24px;
      cursor: pointer;
  }
    
  .close-modal-btn:hover {
      color: #f00;
  }
  .close-modal-btn:focus ~ .modal-overlay{
      opacity: 0;
      visibility: hidden;
  }
  
  .attribute{
      position: absolute;
      bottom: 10px;
  } 