#block1 {
  border: 2px solid #fff;
  height: 200px;
  border-radius: 10px;
  background-image: url("../Components/images/data.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  margin-top:60px;
  display: flex;
  justify-content: space-around;
}

.court1 {
  margin-top: 25px;
  height: 140px;
  width: 107px;
  border-radius: 10px;
  background-color: #3d924a;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  background-image: url("../Components/images/badminton.png");
}

.court1 > div:nth-child(1) {
  margin-left: 18px;
  margin-top: 8px;
  color: rgb(255 255 255);
  font-weight: 600;
  box-shadow: #cccccc1f;
}

.court1 > div:nth-child(2) {
  margin-left: 24px;
  margin-top: 12px;
  color: rgb(255 255 255);
  font-weight: 500;
  margin-right: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}

.court1 > div:nth-child(3) {
  margin-top: 7px;
  color: rgb(255 255 255);
  font-weight: 600;
  /* font-size: 15px; */
  text-align: center;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}

.courts-dropdown{
  position:absolute;
}

