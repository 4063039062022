.filters-container {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin-top: 20px;
  background: white;
  gap: 20px;
  /* width: -webkit-fill-available; */
  /* padding: 17px; */
  justify-content: center;
  height: 251px;
  border-radius: 10px;
}

.filter-dropdown {
  margin-bottom: 10px;
}

.filter-dropdown select {
  width: 200px;
  padding: 8px;
  font-size: 16px;
}

button {
  /* margin-top: 10px; */
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 42px;
}

button:hover {
  background-color: #0056b3;
}

.count {
  display: flex;
  justify-content: center;
}

.displayed-values div {
  margin-bottom: 5px;
  font-size: 75px;
}
.displayed-values div p {
  margin-bottom: 5px;
  font-size: 15px;
}

.count-text {
  margin-top: 20px;
}

.count-container {
  display: flex;
  flex-direction: column;
  padding: 17px;
  /* align-items: center; */
  height: 251px;
  border-radius: 10px;
  background: white;
  width: 560px;
}
